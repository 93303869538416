<template>
   <Sidebar v-if="showNav && show"/>
   <global-search v-if="showGlobalSearch && show"/>
   <!-- main content  -->
   <template v-if="!authChecking">
      <router-view v-slot="{ Component }" v-global-js v-if="show">
         <transition mode="out-in">
            <component :is="Component"/>
         </transition>
      </router-view>
   </template>
   <div class="tooltip d-none" data-tooltip ></div>
   <fixed-btns v-if="show && fixedBtns"/>
   <page-loader />
   <data-loader />
</template>


<script>
   import {ref, onMounted} from 'vue';
   import router           from '@/router/index';
   import store            from '@/store/store';
   import functions        from '@/utils/functions/index';
   import DataLoader       from './components/global/DataLoader.vue';
   import Sidebar          from './components/template/Sidebar.vue';
   import GlobalSearch     from './components/global/GlobalSearch.vue';
   import PageLoader       from './components/global/PageLoader.vue';
   import FixedBtns        from './components/global/FixedBtns.vue';
   
   export default {
      name: 'app',
      components: {
         DataLoader,
         Sidebar,
         GlobalSearch,
         PageLoader,
         FixedBtns,
      },

      setup() {
         let showNav          = ref(true);
         let showGlobalSearch = ref(true);
         let fixedBtns        = ref(true);
         let show             = ref(false);
         let authChecking     = ref(true);

         // set language in cookie if not exists 
         function setLangCookie() {
            if (getCookie(LANG_COOKIE_NAME) !== null) return;
            setCookie(LANG_COOKIE_NAME, DEFAULT_LANG, 1000);
         };
         

         // set the styles of language which is ltr or rtl 
         function setLanguageStyle() {
            let lang      = getCookie(LANG_COOKIE_NAME);
            let link      = document.head.querySelector('[data-sub-css]');
            let linkBsLtr = document.head.querySelector('[data-bootstrap-ltr]');
            let linkBsRtl = document.head.querySelector('[data-bootstrap-rtl]');

            if (lang == 'en') link.href = `${CSS_LTR_FILE_PATH}?modified=${new Date().getTime()}`;
            else link.href = `${CSS_RTL_FILE_PATH}?modified=${new Date().getTime()}`;

            // for bootstrap direction 
            if (lang == 'en') {
               linkBsLtr.href = BS_CDN_LTR_CSS;
               linkBsRtl.href = '';
               
            } else {
               linkBsRtl.href = BS_CDN_RTL_CSS;
               linkBsLtr.href = '';
            } 
         };


         // stop css browser caching 
         function stopCssCaching() {
            let link  = document.querySelector('[data-main-css]');
            let href  = link.getAttribute('href');
            link.href = href + '?modified=' + new Date().getTime();
         };

         // stop js files in public caching
         function stopJsCaching() {
            const scripts  = document.querySelectorAll('[data-config-js-file], [data-functions-js-file]');

            scripts.forEach(script => {
               let src     = script.getAttribute('src');
               script.src  = src + '?modified=' + new Date().getTime();
            })
         };
         
         // make sure user is logged in if not .. show login page 
         function handleRouter() {
            show.value         = true;
            authChecking.value = false;
            
            el('#page-loading').classList.add('hide');
            document.body.style.overflow = 'auto';

            router.beforeEach(async (to, from, next) => {
               if (!store.getters['user/getAuth']) {
                  if (to.meta.auth ?? true) next('/login');
                  else next();
                  
               } else {
                  if (!(to.meta.auth ?? true)) next('/');
                  else next();
               }
            });
         }

         router.beforeResolve((to, from)=> {
            if (getCookie(JWT_COOKIE_NAME) === null) store.dispatch('user/changAuth', false); 

            let isLoggedIn = store.getters['user/getAuth'];
            let needAuth   = to.meta.auth ?? true;

            if (!isLoggedIn && needAuth) router.push('/login');
            else if (isLoggedIn && !needAuth) router.push('/');
         })

         async function isLogged() {
            onMounted(()=> {
               document.body.style.overflow = 'hidden';
            });

            let {data} = await shortFetch('logged', ...skip(3), true, false);

            if (data.ok) {
               await store.dispatch('user/changAuth', true);
               await store.dispatch('user/setUser');
               await store.dispatch('settings/setSettings');
               await store.dispatch('permissions/setPermissions');
               await store.dispatch('shop/setShop');

               if (data.data == 'notification_on') {
                  await store.dispatch('notifications/makeNotification', true);
                  await store.dispatch('notifications/makeNotificationSound', 'on');
                  
                  functions.checkNotification();
               } else {
                  await store.dispatch('notifications/makeNotification', false);
                  await store.dispatch('notifications/makeNotificationSound', 'off');
                  
                  functions.checkNotification();
               }
            } else {
               await store.dispatch('user/changAuth', false);
               router.push('/login');
            }

            handleRouter();
         }

         isLogged();

         onMounted(()=> {
            document.querySelector('html head title').innerHTML = PROJECT_TITLE;
         });
         
         return {
            showNav,
            showGlobalSearch,
            fixedBtns,
            show,
            authChecking,

            setLangCookie,
            setLanguageStyle,
            stopCssCaching,
            stopJsCaching,
         }
      },

      created() {
         this.setLangCookie();
         this.stopCssCaching();
         this.stopJsCaching();
         this.setLanguageStyle();

         this.$watch(() => this.$store.state.config.language, () => this.setLanguageStyle(), {immediate: true});
         this.$watch(() => this.$route,
         () => {
            this.showNav          = this.$route.meta.showNav ?? true
            this.showGlobalSearch = this.$route.meta.showGlobalSearch ?? true
            this.fixedBtns        = this.$route.meta.fixedBtns ?? true

            if (!this.showNav) document.body.classList.add('px-0');
            else document.body.classList.remove('px-0');
         });
      },
   }
</script>

<style lang="scss">
   .v-leave-to,
   .v-enter-from {
      opacity: 0;
      filter: blur(5px);
   }
   
   .v-enter-active,
   .v-leave-active {
      transition: .03s ease;
   }
</style>
