export class CRUDManager {

   async get(url, body = {}, method = 'POST', isFormPost = true, header = undefined, checkToken = true, hasLoader = true) {
      let {data: response} = await shortFetch(url, isFormPost ? formPost(body) : body, method, header, checkToken, hasLoader);

      // log('get', response);
      if (typeof response == 'string') return {data: response, ok: response.ok};
      else if (typeof response == 'number') return {data: response, ok: response.ok};
      else if (isEmpty(response.data)) return {data: [], length: 0, ok: false};

      return {data: response.data, length: response.length, ok: response.ok};
   }

   async insert(url, body = {}, method = 'POST', isFormPost = true, header = undefined, checkToken = true, hasLoader = true) {
      let {data: response} = await shortFetch(url, isFormPost ? formPost(body) : body, method, header, checkToken, hasLoader);
      // log('insert', response);

      return {ok: response.ok, data: response.data};
   }

   async delete(url, body = {}, method = 'POST', isFormPost = true, header = undefined, checkToken = true, hasLoader = true) {
      let {data: response} = await shortFetch(url, isFormPost ? formPost(body) : body, method, header, checkToken, hasLoader);
      // log('delete', response);
      
      return {data: response.data, ok: response.ok};
   }
   
   async update(url, body = {}, method = 'POST', isFormPost = true, header = undefined, checkToken = true, hasLoader = true) {
      let {data: response} = await shortFetch(url, isFormPost ? formPost(body) : body, method, header, checkToken, hasLoader);
      // log('update', response);
      
      return {data: response.data, ok: response.ok};
   }

}